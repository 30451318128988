<template>
  <sequential-entrance fromBottom>
    <v-container fluid>
      <v-row>
        <v-spacer></v-spacer>
        <v-col class="d-flex" cols="12" sm="1">
          <v-tooltip bottom color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="componentDialog = !componentDialog"
                v-bind="attrs"
                v-on="on"
                right
                fab
                small
                color="success white--text"
                style="margin: 5px"
              >
                <v-icon>mdi-clipboard-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>Add New Objective</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <div
            style="padding: 10px"
            v-for="component in component_list"
            :key="component"
          >
            <v-card
              class="mx-auto"
              link
              to="objectives_details"
              max-width="85%"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="text-h6 mb-1">
                  </v-list-item-subtitle>
                </v-list-item-content>
                  <strong>{{ component.name }}</strong>
              </v-list-item>

              <v-card-actions>
                <v-tooltip bottom color="success">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      fab
                      right
                      top
                      absolute
                      rounded
                      color="success"
                      x-small
                      link
                      to="objectives_details"
                    >
                      <v-icon>mdi-bullseye-arrow</v-icon>
                    </v-btn>
                  </template>
                  <span>Objective Targets</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="componentDialog" max-width="60%">
        <v-card>
          <v-card-title><v-icon>mdi-plus</v-icon> New Objective</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
          <v-textarea
            outlined
            name="input-5-3"
            label="Describe Briefly About the Objective"
          ></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="pink darken-1"
              class="white--text"
              small
              @click="componentDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success darken-1"
              small
              class="white--text"
              @click="componentDialog = false"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </sequential-entrance>
</template>

<script>
export default {
  name: "DashBoard",
  data: () => ({
    hovered: true,
    overlay: false,
    componentDialog: false,
    detail: "/components/details/",
    sample: 1,
    component_list: [
      { name: "The Project Development Objective (PDO) is to increase access to high quality broadband internet services for government, businesses and citizens, and to improve the government's capacity to deliver digital public services", id: 1 },
    ],
  }),
};
</script>




